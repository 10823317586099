const society = {
  SOCIETY_ADD_NEW_ACTION: 'Create new company',
  SOCIETY_LABEL_NAME: 'Company name',
  SOCIETY_LABEL_DESCRIPTION: 'Company description',
  SOCIETY_ENTER_DESCRIPTION_PLACEHOLDER: 'Enter description',
  SOCIETY_LABEL_DOMAIN: 'Company domain',
  SOCIETY_ENTER_DOMAIN_PLACEHOLDER: 'For example: company.com',
  SOCIETY_LABEL: 'Companies',
  SOCIETY_EMPTY_SCREEN_DESCRIPTION: `We don't have information about companies to display yet`,
  SOCIETY_EMPTY_SCREEN_USER_DESCRIPTION: `We still don't have information about users corresponding to the company to display.`,
  SOCIETY_RESTORE_PASSWORD_LINK_POPCONFIRM_TITLE:
    'Do you want to send a link to restore password to the indicated user?',
  SOCIETY_LABEL_RESTORE_PASSWORD: 'Restore password',
  SOCIETY_INACTIVATE_USER_POPCONFIRM_TITLE: `Are you sure you want to deactivate this user? You won't be able to log in with it once this action is done.`,
  SOCIETY_DELETE_POPCONFIRM_TITLE: `Are you sure you want to delete this company? Remember that once you do this, it will be deactivated along with the users it comprises, and you won't be able to use it`,
  SOCIETY_FILE_NAME: 'Plika - Companies ({date}})',
  SOCIETY_SELECT_BUDGET_TYPE_TITLE:
    'Enter {{selectText}} the name of the folder where your files will be stored',
  SOCIETY_UPLOAD_S3_FILE_TITLE: 'Uplaod File to Plika - S3 service',
  SOCIETY_SELECT_BUDGET_TYPE_INFORMATIVE_TOOLTIP_TITLE:
    'First, your must enter {{selectText}} the folder name to upload your files',
  SOCIETY_CONFIG_MODAL: 'Company configuration',
  SOCIETY_PLACEHOLDER_BOTTOM_MODULE: 'Module',
  SOCIETY_PLACEHOLDER_BOTTOM_GROUP: 'GROUP',
  SOCIETY_PLACEHOLDER_BOTTOM_SUBMODULE: 'Submodule',
  SOCIETY_PLACEHOLDER_BOTTOM_NAME: 'Bottom Name',
  SOCIETY_PLACEHOLDER_BOTTOM_ACTION: 'Bottom Action',
  SOCIETY_PLACEHOLDER_BOTTOM_FUNCTION: 'Bottom Function',
  SOCIETY_SELECT_FORLDER_NAME_TITLE: 'or select',
  SOCIETY_PLACEHOLDER_PLANNING: 'Planning',
  SOCIETY_PLACEHOLDER_PLANNING_GLOBAL: 'Planning global',
  SOCIETY_PLACEHOLDER_SEGUIMIENTO: 'Seguimiento',
  SOCIETY_PLACEHOLDER_FORECAST: 'Forecast',
  SOCIETY_PLACEHOLDER_FORECAST_GLOBAL: 'Forecast global',
  SOCIETY_PLACEHOLDER_REPORTES: 'Reports',
  SOCIETY_PLACEHOLDER_REPORTES_QUICK: 'Reports self-services Quicksight',
  SOCIETY_ASSIGN_EMAIL_MODAL_TITLE: 'Assign Email',
  SOCIETY_ASSIGNED_EMAIL_FIELD: 'Assigned email',
}

export default society
